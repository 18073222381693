import React, { useState } from "react"
import SEO from './seo'
import HeaderDemolition from './header-demolition'
import FooterDemolition from './footer-demolition'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import QuoteRequestModalLibrary from "./quote-request-modal-library"

export default function LayoutDemolition({ seo, children, env, code, page, bgcolor, footerColor, hidden, defaultStyles, callToAction })
{         
    const bg_color = bgcolor !== undefined ? bgcolor : 'white' 
    const fooC = footerColor !== undefined ? footerColor : ''    
    const main_style = defaultStyles ? { background: bg_color, ... defaultStyles } : { background: bg_color }
    const [fixed, setFixed] = useState(false);

    useScrollPosition(({ prevPos, currPos }) => 
    {
        const heroTitleElement = document.querySelector('.hero-title');
        let heroTitlePosition;
        
        if  (heroTitleElement) {
            heroTitlePosition = heroTitleElement.getBoundingClientRect().bottom + window.scrollY;
        }
        
        const customPosition = heroTitlePosition ? heroTitlePosition * -1 : -80;
        // We convert to negative the value of the absolute element to match the relative value of currPos.y

        const showForm = currPos.y < customPosition;

        const header = document.getElementsByTagName('header');

        header[0].className = hidden === true ? '' : ( showForm === true ? 'showform' : '' );
        if (callToAction && typeof window !== "undefined" && window.innerWidth < 992 && showForm) {
            setFixed(true)
        } else {
            setFixed(false);
        }
    })
    
    return (
        <div id="wrapper">            

            <SEO seo={seo} />
            
            <header>
                <HeaderDemolition env={env} code={code} page={page}/>                
            </header>

            <main style={main_style}>
                {children}
                {callToAction && fixed &&
                <div className="sidebar-container">
                    <div id="fixed-btns" className="sidebar-call-to-action fixed-btn" >
                        <div className="sidebar-call-to-action-container">
                            <QuoteRequestModalLibrary
                                startVisible={false}
                                ondeffCode="demolitionQuoteRequest"
                                btnLabel='Get matched with pros'
                                class="get-a-quote"
                            />
                            <p className="sidebar-bottom-message">Share project details to get started</p>
                        </div>
                    </div>
                </div>
                }
            </main>
        
            <footer className={fooC}>
                <FooterDemolition />
            </footer> 
        </div>
    ) 
}