import React, { useState, useEffect } from "react"
import HeaderMenuDemolition from "./header-menu-demolition"
import ZipFinderKppDemolition from "./zip-finder-kpp-demolition"

export default function HeaderDemolition(props) {
  const env = props.env
  const code = props.code
  const page = props.page
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState)
  }

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
    }

    return () => {
      document.body.classList.remove("no-scroll")
    }
  }, [isMenuOpen])

  return (
    <nav id="sitenav" className="col-12 navbar navbar-expand-md navbar-light">
      <div className={`elements ${!isMenuOpen ? "is-visible" : ""}`}>
        <button
          className="navbar-toggler"
          type="button"
          aria-controls="navbarSupportedContent"
          aria-expanded={isMenuOpen}
          aria-label="Toggle navigation"
          onClick={toggleMenu}
        >
          <span className="sr-only"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>

        <a className="nav-bar brand" href="/" title="Home">
          <img
            src="/images/logo-demolition.webp"
            alt="logo"
            loading="eager"
          ></img>
        </a>
      </div>

      <HeaderMenuDemolition isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />

      <ZipFinderKppDemolition
        id={"header-zip-finder"}
        page={page}
        env={env}
        type={"small"}
        code={code}
        button={"Get Started"}
        finderopenmodal={true}
      />
    </nav>
  )
}
