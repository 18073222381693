import React, { lazy, Suspense } from 'react';
import Cookies from 'js-cookie' //https://www.npmjs.com/package/js-cookie
import Breadcrumb from './breadcrumb';

import Modal from 'react-modal';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import CloseModalConfirmation from './closeModalConfirmation';

const OnDeffImplementation = lazy(() => import('./on-deff-implementation'))


export default class ZipFinderKppDemolition extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            zip: '',
            showModal: false,
            loading: false,
            closeModal: false
        }

        if (this.props.state !== undefined) {
            this.place =
            {
                name: undefined,
                state:
                {
                    name: this.props.state.name,
                    av: this.props.state.av
                }
            }
        } else {
            this.place = this.props.place;
        }

        this.code = this.props.code;
        this.codes = this.props.codes;
        this.service = process.env.GATSBY_DRUPAL_URL !== undefined ? `${process.env.GATSBY_DRUPAL_URL}` : 'https://bookable-api-dev.azurewebsites.net';
        this.type = this.props.type;
        this.env = this.props.env;
        this.finderopenmodal = this.props.finderopenmodal;

        //Quote request props
        this.proId = this.props.proid;
        this.startVisible = this.props.startVisible ? true : false;

        this.initialValuePoolLimit = 0;
        if (typeof window !== 'undefined') {
            const params = new URLSearchParams(window.location.search)
            if (params.get("test") == "true") {
                this.initialValuePoolLimit = 1;
            }
        }
        this.limitPoolSize = this.initialValuePoolLimit;

        this.modalStyles = {
            content:
            {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                borderRadius: '10px',
                border: 'none',
                backgroundColor: '#FFFFFF',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                padding: '0px',
                minHeight: 'auto',
                width: '600px',
            }
        }

        this.childRef = React.createRef();
    }

    changeShowModal = () => {
        if(!this.state.showModal) {
            this.setState({ ...this.state, showModal: true });
        } else {
            const submitMessageElement = document.querySelector('.submitMessageGA');
            if(!submitMessageElement) {
                this.setState({ ...this.state, closeModal: true });
            } else {
                this.setState({ ...this.state, showModal: false });
            }
        }

    }

    handleCloseModal = () => {
        this.setState({ ...this.state, showModal: false, closeModal: false });
    }

    handleContinueForm = () => {
        this.setState({ ...this.state, closeModal: false });
    }

    setLoading = () => {
        this.setState({ ...this.state, loading: !this.state.loading });
    }

    addBreadcrumb = () => {
        if (this.props.state !== undefined || this.props.breadcrumb) {
            return (
                <Breadcrumb
                    code={this.code}
                    place={this.place}
                    codes={this.codes}
                    url={this.props.url} />
            )
        }
    }

    pickBg = () => {
        let bg = 'bk-' + this.code;

        if (bg === 'bk-home') {
            bg = 'bk-home-' + this.env.site;
        }

        return bg
    }



    handleSubmit = event => {
        event.preventDefault();

        const zip = this.state.zip;

        if (!this.validateValue(zip)) {
            this.showError('Zip Code is required');
            return;
        }

        if (!this.validateNumber(zip)) {
            this.showError('The zip code must have 5 number');
            return;
        }


        if (this.finderopenmodal) {
            this.changeShowModal()
        } else this.getUrl(zip, true);

    }

    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    validateValue = value => {
        return value !== '' ? true : false;
    }

    validateNumber = value => {
        const regex = new RegExp('^\\d{5}$');
        return regex.test(value);
    }

    getUrl = (zip, redirect) => {
        let codes = this.code === 'home' || this.code === 'demolition' ? 'demolition-contractors' : this.code;
        let endpoint = '/api/v1/kpp/keywords/' + codes + '/zip_codes/' + zip;
        const url = this.service + endpoint;
        const headers = {};
        headers['Ocp-Apim-Subscription-Key'] = process.env.GATSBY_API_MANAGMENT_KEY;

        fetch(url,
            {
                method: 'get',
                headers: headers
            })
            .then((response) => {
                if (response.status === 400) {
                    return '/redirect/no-zip-found';
                }

                return response.json();
            })
            .then((response) => {
                this.cookies(zip);

                this.setState({ 'redirect': response });

                if (redirect) {
                    this.redirect(response);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    cookies = (zip) => {
        Cookies.set('zipcode', zip);
    }

    showError = text => {
        const input = document.getElementById(this.props.id);

        input.value = '';
        input.placeholder = text;
    }

    redirect = url => {
        if (typeof url !== 'object' && !Array.isArray(url)) {
            window.location.replace('/' + url);
        } else {
            window.location.replace('/redirect/no-zip-found');
        }
    }

    getFinder = type => {
        switch (type) {
            case 'small':
                return (

                    <div className="zip-finder-small mb-5">
                        <div className="card">
                            <form onSubmit={this.handleSubmit}>

                                <input
                                    id={this.props.id}
                                    className="form-control"
                                    type="text"
                                    name="zip"
                                    value={this.state.zip}
                                    placeholder="Enter Zip Code"
                                    onChange={this.handleInputChange}
                                    maxLength="5"
                                />

                                <input className="btn btn-primary" type="submit" value={this.props.button} />
                            </form>
                        </div>
                    </div>

                )

            default:
                return (
                    <div className="zip-finder dumpster smallest">
                        <div className="container-fluid">

                            {this.addBreadcrumb()}

                            <section className="row">

                                <div className={`header-image header-image-home col-12 ${this.pickBg()}`} >
                                    <div className="row justify-content-center align-items-center h-100">
                                        <div className="col-md-12 col-lg-8 hero-title text-center">

                                            <h1>{this.props.title}</h1>
                                            <h3>{this.props.text}</h3>

                                            <form className="row justify-content-center" onSubmit={this.handleSubmit}>
                                                <div className="col-md-12 col-lg-8 col-lg-offset-2 search">
                                                    <div className="input-group" id="searcher">

                                                        <input
                                                            id={this.props.id}
                                                            className="form-control"
                                                            type="search"
                                                            name="zip"
                                                            value={this.state.zip}
                                                            placeholder="Enter Zip Code"
                                                            onChange={this.handleInputChange}
                                                            maxLength='5'
                                                        />

                                                        <button
                                                            type="submit"
                                                            id="getStarted"
                                                            className="btn btn-primary btn-sm">{this.props.button}</button>

                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="row title">
                                <div className="col-12">
                                    <h3 className="justify-content-center text-white">America's #1 Source for Local Demolition Experts</h3>
                                </div>
                            </section>
                        </div>
                    </div>

                )
        }
    }

    render() {
        return (<>
            {this.getFinder(this.type)}
            <Modal
                id={1}
                isOpen={this.state.showModal}
                style={this.modalStyles}
                contentLabel="Get a Quote!"
                shouldCloseOnOverlayClick={false}
                preventScroll={true}
                ariaHideApp={false}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <>
                    <div className={this.state.closeModal ? "display-none-form" : "modal-header"}>
                        <button type="button" className="close closermodal" onClick={this.changeShowModal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div id="quote-steps-modal-library" className={this.state.closeModal ? 'display-none-form' : ''}>
                        <Suspense fallback={<h1>Loading…</h1>}>
                            <OnDeffImplementation
                                initialData={[{ key: 'pro_id', value: this.proId }, { key: 'limit_poolsize', value: this.limitPoolSize }]}
                                formCode={'demolitionQuoteRequest'}
                                loadingFunction={this.setLoading}
                                ref={this.childRef}>
                            </OnDeffImplementation>
                        </Suspense>
                    </div>
                </>
                {this.state.closeModal &&
                <CloseModalConfirmation
                    handleContinueForm={this.handleContinueForm}
                    handleCloseModal={this.handleCloseModal}
                />
                }
            </Modal></>
        )
    }
}