import React from 'react'

export default class ServicesDemolition extends React.Component {

    render() {
       
            return (

                <div className="services mb-5">
                    <div className="card demolition">
                        <h3>Find Experts for Your Project</h3>
                        <a href="/" className="service">All Demolition Contractors</a>
                        <a href="/barn-demolition" className="service">Barn Demolition</a>
                        <a href="/chimney-removal" className="service">Chimney Removal</a>
                        <a href="/commercial-demolition" className="service">Commercial Demolition</a>
                        <a href="/concrete-removal" className="service">Concrete Removal</a>
                        <a href="/deck-and-fence-removal" className="service">Deck and Fence Removal</a>
                        <a href="/house-and-garage-demolition" className="service">House and Garage Demolition</a>
                        <a href="/interior-demolition" className="service">Interior Demolition</a>
                        <a href="/mobile-home-demolition" className="service">Mobile Home Removal</a>
                        <a href="/pool-removal" className="service">Pool Removal</a>
                        <a href="/tank-removal" className="service">Tank Removal</a>
                    </div>
                </div>

            )
    }

}
